import { Injectable } from "@angular/core";
import LocalSessionDataSource from "src/app/wrappers/LocalSessionDataSource";

@Injectable({
    providedIn: 'root',
})
export default class SessionAngularService {
    public localSessionDataSource: LocalSessionDataSource;

    constructor() {
        this.localSessionDataSource = new LocalSessionDataSource();
    }
}
